<template>
  <div>
    <div v-if="this.loading === false">
      <h1>{{ item[0].title }}</h1>
      <div class="row-fluid case">
        <div v-if="showDetails === 'yes'" class="case__details">
          <div v-if="getMetaFieldVisibility('created')" class="meta">
            <span class="date">Kirjattu {{ convertDate(cases.created) }}</span>
          </div>
          <p v-if="getMetaFieldVisibility('description')">{{ cases.description }}</p>
          <p>
            <button class="btn-link" @click="toggleCollapse" :aria-expanded="aria['expanded']"
              aria-controls="collapsible-0"> {{ buttonText }}
            </button>
          </p>
          <div :class="aria['classes'] + ' accordion__content'" id="collapsible-0"
            :aria-hidden="aria['hidden']">
            <p v-if="getMetaFieldVisibility('status')">Tila: {{ cases.status }}</p>
            <p v-if="getMetaFieldVisibility('id')">Asianumero: {{ cases.caseNumber }}</p>
            <p v-if="getMetaFieldVisibility('keyword')">
              Avainsanat:
              <span v-for="(keyword, index) in cases.subject" :key="index">{{ cases.title }}</span>
            </p>
            <p v-if="getMetaFieldVisibility('abstract')">Lyhennelmä: {{ cases.abstract }}</p>
            <p v-if="getMetaFieldVisibility('organization')">Organisaatio: {{
                getOrganizationShort(cases.caseNumber)
              }}
            </p>
          </div>
        </div>
        <div class="case__files" v-if="getFiles.length > 0">
          <h4 class="documents-heading">
            <span v-if="getFiles.length > 1" v-translate>Asiakirjat</span>
            <span v-else v-translate>Asiakirja</span>
          </h4>
          <div v-for="(file, index) in getFiles" :key="index" class="feed-item simple">
            <h6>
              <a :href="getApiUrl+'/vahva/documents/'+file.sahkeDocument.nativeId" class="external">{{
                  file.sahkeDocument.fileName
                }}</a> ({{ file.sahkeDocument.formatName }})
            </h6>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <Loader />
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
import Loader from '@/components/Loader';
import moment from 'moment';
import axios from 'axios';

export default {
  props: ['id', 'itemCaseNumber'],
  components: {
    Loader
  },
  data: () => ({
    organization: '',
    item: [],
    files: [],
    cases: [],
    documents: [],
    records: [],
    actions: [],
    loading: true,
    recordId: '',
    buttonText: 'Näytä enemmän tietoa',
    aria: {
      expanded: 'false',
      hidden: 'true',
      classes: 'hidden'
    }
  }),
  async mounted() {
    this.getRecordFromApi();
  },
  computed: {
    ...mapGetters({
      getFilteredItems: 'getFilteredItems',
      getVisibleFields: 'getConfigurationDataFields',
      getType: 'getConfigurationType',
      getShowDetails: 'getConfigurationShowCompilation',
      getApiUrl: 'getApiUrl',
      getMetaFields: 'getConfigurationMetaData',
    }),
    getCase: function () {
      return this.getFilteredItems.filter(item => item.nativeId === this.id);
    },
    showDetails: function () {
      if (this.getShowDetails != undefined) {
        return this.getShowDetails;
      }
    },
    getFiles: function () {
      console.log('ITEMS', this.item);
      return this.files;
    },
    getDocuments: function () {
      if (this.documents.length > 0 && this.documents != undefined) {
        return this.documents;
      }
    },
  },
  methods: {
    getVisibility: function (field) {
      if (this.getVisibleFields !== undefined) {
        return this.getVisibleFields.includes(field);
      }
    },
    getMetaFieldVisibility(field) {
      if (this.getMetaFields != undefined) {
        return this.getMetaFields.includes(field);
      }
    },
    convertDate(value) {
      value = value.replace(/\./g,':');
      return value ? moment(value).format('D.M.YYYY') : '';
    },
    getOrganizationShort(str) {
      if (str != undefined) {
        return str.split('/')[0];
      }
    },
    getRecordFromApi() {
      axios.get(this.getApiUrl + '/vahva/records/' + this.id)
        .then(response => {
          console.log('RESPONSE', response.data, this.id, this.getApiUrl + '/vahva/records/' + this.id);
          Object.entries(response.data.documents).forEach(([key, value]) => {
            this.item.push(value);
          });
          Object.entries(this.item).forEach(([key, value]) => {
            Object.entries(value.document).forEach(([key, value]) => {
              this.files.push(value);
            });
          });

          new Promise((resolve) => {
            this.getCaseFromApi(this.item[0].caseNumber);
            resolve();
          });
          /* */
          this.loading = false;
        })
        .catch(error => {
          console.log('error', error.message);
        });
    },
    getCaseFromApi(id) {
      axios.get(this.getApiUrl + '/vahva/cases?caseNumber=' + id)
        .then(response => {
          if (response.data != '') {
            new Promise((resolve) => {
              console.log('BY CASE number', response.data);
              this.recordId = response.data.cases[0].nativeId;
              this.getCaseByIdFromApi(response.data.cases[0].nativeId);
              resolve();
            });
          }
        })
        .catch(error => {
          console.log('error', error.message);
        });
    },
    toggleCollapse() {
      this.aria['expanded'] = this.aria.expanded === 'false' ? 'true' : 'false';
      this.aria['hidden'] = this.aria.hidden === 'true' ? 'false' : 'true';
      this.aria['classes'] = this.aria.classes === 'hidden' ? 'visible' : 'hidden';
      this.buttonText = this.buttonText === 'Näytä enemmän tietoa' ? 'Näytä vähemmän tietoa' : 'Näytä enemmän tietoa';
    },
    getCaseByIdFromApi(id) {
      axios.get(this.getApiUrl + '/vahva/cases/' + this.recordId)
        .then(response => {
          if (response.data != '') {

            new Promise((resolve) => {
              this.cases = response.data.cases[0];
              resolve();
            });
          }
        }).catch(error => {
        console.log('error get querying case', error.message);
      });
    },
    getDocumentsByIndex(i, field) {
      for (const [index, document] of this.documents.entries()) {
        console.log(field, document[field]);
        if (index === 1) {
          if (field === 'type') {
            return document[field].type;
          } else if (field === 'date') {
            document['date'] = document['date'].replace(/\./g,':');
            return moment(document['date']).format('D.M.YYYY');
          } else {
            return document[field]
          }
        }
      }
    }
  }
}
</script>
