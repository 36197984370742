<template>
  <div>
    <div v-if="this.loading === false">
      <!--<h3 class="">{{ item.title }}</h3>-->
      <div class="row-fluid case">
        <h6 v-if="getVisibility('title')">{{ item.title }}</h6>
        <div v-if="showDetails === 'yes'" class="case__details">
          <div v-if="getVisibility('created')" class="meta">
            <span class="date">Kirjattu {{ convertDate(item.created) }}</span>
          </div>
          <p v-if="getVisibility('description') && hasValue(item.description)">{{ item.description }}</p>
          <p class="more__info">
            <button class="btn-link" @click="toggleCollapse" :aria-expanded="aria['expanded']"
              aria-controls="collapsible-0">
              {{ buttonText }}
            </button>
          </p>
          <div :class="aria['classes'] + ' accordion__content'" id="collapsible-0"
            :aria-hidden="aria['hidden']">
            <p>
              Asianumero: {{ item.caseNumber }}
            </p>
            <p v-if="getVisibility('id') && hasValue(item.nativeId)">
              Id: {{ item.nativeId }}
            </p>
            <p v-if="getVisibility('status') && hasValue(item.status)">
              Tila: {{ item.status }}
            </p>
            <p v-if="getVisibility('abstract') && hasValue(item.abstract)">
              Lyhennelmä: {{ item.abstract }}
            </p>
            <p v-if="getVisibility('organization') && hasValue(item.caseNumber)">
              Organisaatio: {{ getOrganizationShort(item.caseNumber) }}
            </p>
            <p v-if="getVisibility('keyword') && hasValue(item.subject)">
              <br>Avainsanat:
              <span v-for="keyword in item.subject">
                <label class="feed-item-aria-label" :id="'feed-case-aria-label-'+item.nativeId+'-'+keyword.recno">
                  {{ keyword.title }}
                </label>
                <a :aria-labelledby="'feed-document-aria-label-'+item.nativeId+'-'+keyword.recno"
                  :href="keyword.description" class="external">
                  {{ keyword.title }}
                </a>
              </span>
            </p>
          </div>
        </div>
        <div class="case__files">
          <div class="feed-item simple" v-for="(file,index) in getItemFiles(documents)" :key="index">
            <label class="feed-item-aria-label"
                   :id="'feed-case-aria-label-file-'+file.nativeId">{{ file.itemTitle }}
            </label>
            <a :aria-labelledby="'feed-case-aria-label-file-'+file.nativeId"
               :href="getApiUrl+'/vahva/documents/'+file.nativeId" class="attachment external">
              {{ file.itemTitle }}
              <span v-if="getMetaFieldVisibility('format') && hasValue(file.formatName)" class="file-ext">
                {{ file.formatName }}
              </span>
              <span aria-hidden="true" class="glyphicon glyphicon-file"></span>
            </a>
            <div class="meta">
              <p>
                <span class="date" v-if="getMetaFieldVisibility('id') && hasValue(file.caseNumber)">{{ file.caseNumber }}</span>
                <span class="date" v-if="getMetaFieldVisibility('created') && hasValue(file.created)">{{ file.created }}</span>
                <span class="date" v-if="getMetaFieldVisibility('nativeid') && hasValue(file.nativeId)">{{ file.nativeId }}</span>
                <span class="date" v-if="getMetaFieldVisibility('abstract') && hasValue(file.abstract)">{{ file.abstract }}</span>
                <span class="date" v-if="getMetaFieldVisibility('publisher') && hasValue(file.caseNumber)">{{ getOrganizationShort(file.caseNumber) }}</span>
                <span class="date" v-if="getMetaFieldVisibility('filename') && hasValue(file.fileName)">{{ file.fileName }}</span>
                <span class="date" v-if="getMetaFieldVisibility('type') && hasValue(file.type)">{{ file.type }}</span>
                <span class="date keywords" v-if="getMetaFieldVisibility('keyword') && hasValue(file.subject)">
                  <br>Avainsanat:
                  <span v-for="keyword in file.subject">
                    <label class="feed-item-aria-label"
                           :id="'feed-document-aria-label-'+file.nativeId+'-'+keyword.recno">
                      {{ keyword.title }}
                    </label>
                    <a :aria-labelledby="'feed-document-aria-label-'+file.nativeId+'-'+keyword.recno"
                       :href="keyword.description" class="external">
                      {{ keyword.title }}
                    </a>
                  </span>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div class="case__files" v-if="getItemFiles(documents).length == 0">
          Ei näytettäviä dokumentteja
        </div>
      </div>
    </div>
    <div v-else>
      <Loader />
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  import Loader from '@/components/Loader';
  import moment from 'moment';
  import axios from 'axios';

  export default {
    props: ['id'],
    components: {
      Loader
    },
    data: () => ({
      organization: '',
      item: [],
      files: [],
      documents: [],
      records: [],
      actions: [],
      loading: true,
      buttonText: 'Näytä enemmän tietoa',
      aria: {
        expanded: 'false',
        hidden: 'true',
        classes: 'hidden'
      }
    }),
    async mounted() {
      this.getCaseFromApi();
    },
    computed: {
      ...mapGetters({
        getFilteredItems: 'getFilteredItems',
        getVisibleFields: 'getConfigurationDataFields',
        getType: 'getConfigurationType',
        getShowDetails: 'getConfigurationShowCompilation',
        getApiUrl: 'getApiUrl',
        getMetaFields: 'getConfigurationMetaData',
        getConfigurationResultSource: 'getConfigurationResultSource'
      }),
      getCase: function () {
        return this.getFilteredItems.filter(item => item.nativeId === this.id);
      },
      showDetails: function () {
        if (this.getShowDetails != undefined) {
          return this.getShowDetails;
        }
      },
      getFiles: function () {
        return this.files;
      },
      getDocuments: function () {
        if (this.documents.length > 0 && this.documents != undefined) {
          return this.documents;
        }
      },
    },
    methods: {
      getMetaFieldVisibility(field) {
        return this.getMetaFields.includes(field);
      },
      getVisibility: function (field) {
        if (this.getVisibleFields !== undefined) {
          return this.getVisibleFields.includes(field);
        }
      },
      convertDate(date) {
        date = date.replace(/\./g,':');
        return date ? moment(date).format('D.M.YYYY') : '';
      },
      getOrganizationShort(str) {
        if (str != undefined) {
          return str.split('/')[0];
        }
      },
      getCaseFromApi() {
        axios.get(this.getApiUrl + '/vahva/cases/' + this.id)
          .then(response => {
            this.item = response.data['cases'][0];
            this.actions = this.item.action;
            new Promise((resolve) => {
              Object.entries(this.actions).forEach(([key, value]) => {
                const items = value.record;
                Object.entries(items).forEach(([key, value]) => {
                  this.getRecordsFromApi(value.nativeId);
                  this.records.push(value);

                });
              });

              Object.entries(this.records).forEach(([key, value]) => {
                const document = value.document;

                Object.entries(document).forEach(([key, value]) => {
                  this.files.push(value.sahkeDocument);
                });
              });

              resolve();
            });

            this.loading = false;
          })
          .catch(error => {
            console.log('error', error.message);
          });
      },
      getRecordsFromApi(id) {
        axios.get(this.getApiUrl + '/vahva/records/' + id + '/' + this.getConfigurationResultSource)
          .then(response => {
            if (response.data != '') {
              new Promise((resolve) => {
                Object.entries(response.data.documents).forEach(([key, value]) => {
                  this.documents.push(value);
                });

                resolve();
              });
            }
          })
          .catch(error => {
            console.log('error', error.message);
          });
      },
      toggleCollapse() {
        this.aria['expanded'] = this.aria.expanded === 'false' ? 'true' : 'false';
        this.aria['hidden'] = this.aria.hidden === 'true' ? 'false' : 'true';
        this.aria['classes'] = this.aria.classes === 'hidden' ? 'visible' : 'hidden';
        this.buttonText = this.buttonText === 'Näytä enemmän tietoa' ? 'Näytä vähemmän tietoa' : 'Näytä enemmän tietoa';
      },
      getDocumentsByIndex(i, field) {
        for (const [index, document] of this.documents.entries()) {
          if (index === 1) {
            if (field === 'type') {
              return document[field].type;
            } else if (field === 'date') {
              document['date'] = document['date'].replace(/\./g,':');
              return moment(document['date']).format('D.M.YYYY');
            } else {
              return document[field];

            }
          }
        }
      },
      hasValue(item) {
        if (item === null || item === '' || item.length === 0) {
          return false;
        }
        return true;
      },
      getItemFiles(items) {
        let files = [];
        items.forEach(item => {
          item.document.forEach(document => {
            let file = {};
            file.created = null;
            if (item.created != null && item.created != "") {
              item.created = item.created.replace(/\./g, ':');
              file.created = moment(item.created).format('D.M.YYYY');
            }
            file.caseNumber = item.caseNumber;
            file.abstract = item.abstract;
            file.description = item.description;
            file.subject = item.subject;
            file.storageLocation = item.storageLocation;
            file.type = item.type.type;
            file.itemTitle = item.title;
            file.nativeId = document.sahkeDocument.nativeId;
            file.fileName = document.sahkeDocument.fileName;
            file.formatName = document.sahkeDocument.formatName;
            files.push(file);
          });
        });
        return files;
      },
    }
  };
</script>
