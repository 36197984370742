<script src="../router.js"></script>
<template>
  <div>
    <div v-if="!getFilteredItems || getFilteredItems.length === 0">
      <Loader />
    </div>
    <div v-else>
      <div v-if="getFilteredItems && getFilteredItems.length === 1 && getType === 'cases'">
        <Case :id='this.getFilteredItems[0].nativeId' />
      </div>
      <div v-else>
        <v-data-iterator
          :items="sortList"
          :items-per-page.sync="itemsPerPage"
          hide-default-footer
          :page="page"
        >
          <template v-slot:default="props">
            <div v-if="getType === 'cases'">
              <div
                v-for="item in props.items"
                :key="item.title"
                class="feed-item simple"
              >
                <h6>
                  <label class="feed-item-aria-label" :id="'feed-case-aria-label-'+item.nativeId">
                    {{ item.title }}
                  </label>
                  <router-link :aria-labelledby="'feed-case-aria-label-'+item.nativeId"
                    :to="{ path: contextUrl + item.nativeId }">
                    {{ item.title }}
                  </router-link>
                </h6>
                <div class="meta">
                  <p>
                    <span class="date" v-if="getDataFieldVisibility('id')">{{ item.caseNumber }}</span>
                    <span class="date" v-if="getDataFieldVisibility('created') && hasValue(item.created)">{{ convertDate(item.created) }}</span>
                    <span class="date" v-if="getDataFieldVisibility('nativeid') && hasValue(item.nativeId)">{{ item.nativeId }}</span>
                    <span class="date" v-if="getDataFieldVisibility('abstract') && hasValue(item.abstract)">{{ item.abstract }}</span>
                    <span class="date" v-if="getDataFieldVisibility('status') && hasValue(item.status)">{{ item.status }}</span>
                    <span class="date keywords" v-if="getDataFieldVisibility('keyword') && hasValue(item.subject)">
                        <br>Avainsanat:
                        <span v-for="keyword in item.subject" v-bind:key="kwIndex">
                          <label class="feed-item-aria-label"
                            :id="'feed-case-aria-label-'+item.nativeId+'-'+keyword.recno">
                            {{ keyword.title }}
                          </label>
                          <a :aria-labelledby="'feed-case-aria-label-'+item.nativeId+'-'+keyword.recno"
                            :href="keyword.description" class="external">
                            {{ keyword.title }}
                          </a>
                        </span>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="feed-item-container" v-for="item in props.items" :key="item.title">
                <div class="feed-item simple" v-for="(file,index) in getItemFiles(item)" :key="index">
                  <h6 v-if="index === 0">
                    {{ file.itemTitle }}
                  </h6>
                  <a :href="getApiUrl+'/vahva/documents/'+file.nativeId" class="attachment external">
                    {{ file.fileName.replace(/_/g, ' ') }}
                    <span v-if="getMetaFieldVisibility('format') && hasValue(file.formatName)" class="file-ext">
                      {{ file.formatName }}
                    </span>
                    <span role="presentation" aria-hidden="true" class="glyphicon glyphicon-file"></span>
                  </a>
                  <div class="meta">
                    <p>
                      <span class="date" v-if="getMetaFieldVisibility('id') && hasValue(file.caseNumber)">{{ file.caseNumber }}</span>
                      <span class="date" v-if="getMetaFieldVisibility('created') && hasValue(file.created)">{{ file.created }}</span>
                      <span class="date" v-if="getMetaFieldVisibility('nativeid') && hasValue(file.nativeId)">{{ file.nativeId }}</span>
                      <span class="date" v-if="getMetaFieldVisibility('abstract') && hasValue(file.abstract)">{{ file.abstract }}</span>
                      <span class="date" v-if="getMetaFieldVisibility('publisher') && hasValue(file.caseNumber)">{{ getOrganizationShort(file.caseNumber) }}</span>
                      <span class="date" v-if="getMetaFieldVisibility('filename') && hasValue(file.fileName)">{{ file.fileName }}</span>
                      <span class="date" v-if="getMetaFieldVisibility('type') && hasValue(file.type)">{{ file.type }}</span>
                      <span class="date keywords" v-if="getMetaFieldVisibility('keyword') && hasValue(file.subject)">
                          <br>Avainsanat:
                          <span v-for="keyword in file.subject" v-bind:key="kwIndex">
                            <label class="feed-item-aria-label"
                              :id="'feed-document-aria-label-'+file.nativeId+'-'+keyword.recno">
                              {{ keyword.title }}
                            </label>
                            <a :aria-labelledby="'feed-document-aria-label-'+file.nativeId+'-'+keyword.recno"
                              :href="keyword.description" class="external">
                              {{ keyword.title }}
                            </a>
                          </span>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template v-slot:footer>
            <nav class="lfr-pagination" v-show="!hidePagination && numberOfPages > 1" role="navigation"
              aria-label="Sivutus">
              <ul class="pager lfr-pagination-buttons">
                <li :class="{ 'pagination-button-disabled disabled' : !pagerPrev }" :aria-disabled="!pagerPrev ">
                  <a :class="{ 'pagination-button-disabled disabled' : !pagerPrev }" @click="formerPage" v-translate>Edellinen</a>
                </li>
                <li :class="{ 'pagination-button-disabled disabled' : !pagerNext} ">
                  <a :class="{ 'pagination-button-disabled disabled' : !pagerNext}" @click="nextPage" :aria-disabled="!pagerNext" v-translate>Seuraava</a>
                </li>
              </ul>
            </nav>
          </template>
        </v-data-iterator>
      </div>
    </div>
  </div>
</template>
<script>

import {mapActions, mapGetters} from 'vuex';
import moment from 'moment';
import Loader from './Loader';
import Case from './Case';
import Record from './Record';

export default {
  components: {
    Record,
    Loader,
    Case
  },
  data: () => ({
    defaultFields: ['created', 'id'],
    itemsPerPage: 10,
    hidePagination: false,
    page: 1,
    pagerPrev: false,
    pagerNext: false
  }),
  created() {
  },
  mounted() {
    this.$nextTick(() => {
      this.paginationStatus;
    });
  },
  beforeUpdate() {
    this.configItemsPerPage();
    this.getPagerStatus();
  },
  computed: {
    ...mapGetters({
      getFilteredItems: 'getFilteredItems',
      getType: 'getConfigurationType',
      getVisibleFields: 'getConfigurationDataFields',
      getMetaFields: 'getConfigurationMetaData',
      getSortingOrder: 'getSortOrder',
      getConfigPagination: 'getConfigurationPagination',
      getApiUrl: 'getApiUrl',
      getConfigurationResultSource: 'getConfigurationResultSource',
      getConfigurationKeywords: 'getConfigurationKeywords',
      getConfigurationListingAs: 'getConfigurationListingAs',
      getConfigurationSearchMethod: 'getConfigurationSearchMethod'
    }),
    getPagination: function () {
      return parseInt(this.getConfigPagination, 10);
    },

    contextUrl: function () {
      let path;
      if (this.getType === 'records') {
        path = '/asiakirja/';
      } else {
        path = '/asia/';
      }
      return path;
    },
    sortList: function () {
      if (this.getFilteredItems !== undefined) {

        if (this.getSortingOrder === 'latest') {
          return this.getFilteredItems.sort((a, b) => b.created.localeCompare(a.created));
        } else {
          return this.getFilteredItems.sort((a, b) => a.title.localeCompare(b.title));
        }
      }
    },
    paginationStatus: function () {
      if (this.getPagination === 0) {
        this.hidePagination = true;
        this.itemsPerPage = this.sortList.length;

      } else {
        this.itemsPerPage = this.getPagination;

      }


    },
    numberOfPages: function () {
      return (this.sortList && this.sortList.length && this.itemsPerPage) ? Math.ceil(this.sortList.length / this.itemsPerPage) : 0;

    },
    showPagination: function () {
      if (this.numberOfPages < 2) {
        return this.hidePagination = true;
      } else {
        return this.hidePagination = false;
      }
    }
  },
  methods: {
    ...mapActions({
      setFilteredList: 'setFilteredList'
    }),
    configItemsPerPage: function () {
      if (this.getPagination !== 0) {
        return this.itemsPerPage = this.getPagination;
      } else {
        return this.itemsPerPage = this.getFilteredItems.length;
      }

    },
    getItems: function () {
      let filteredItems = [];
      if (this.getConfigurationListingAs === 'automatic' && this.getConfigurationKeywords) {
        // Separated just to make this more clear
        if (this.getConfigurationSearchMethod === 'free') {
          filteredItems = this.getFilteredItems.filter((item)=>{
            const stringIndex = item.title.toLowerCase().indexOf(this.getConfigurationKeywords.replace(/%/g, '').toLowerCase(),0);
            return (stringIndex !== -1);
          });
        } else if (this.getConfigurationSearchMethod === 'fromStart') {
          filteredItems = this.getFilteredItems.filter((item)=>{
            const stringIndex = item.title.toLowerCase().indexOf(this.getConfigurationKeywords.replace(/%/g, '').toLowerCase(),0);
            return (stringIndex !== -1 && stringIndex === 0);
          });
        } else {
          filteredItems = this.getFilteredItems.filter((item)=>{
            return (item.title.toLowerCase() === this.getConfigurationKeywords.replace(/%/g, '').toLowerCase());
          });
        }
        this.$store.dispatch('setFilteredList', filteredItems);
      }
    },
    convertDate(date) {
      date = date.replace(/\./g,':');
      return moment(date).format('D.M.YYYY');
    },
    getDataFieldVisibility(field) {
      return this.getVisibleFields.includes(field);
    },
    getMetaFieldVisibility(field) {
      return this.getMetaFields.includes(field);
    },
    getOrganizationShort(str) {
      return str.split('/')[0];
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) {
        this.page += 1;
      }
      this.getPagerStatus();
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
      }
      this.getPagerStatus();
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
    getPagerStatus() {
      this.pagerPrev = this.page !== 1;

      this.pagerNext = this.page !== this.numberOfPages;
    },
    getItemFiles(item) {
      let files = [];
      item.document.forEach(document => {
        let file = {};
        file.created = null;
        if (item.created != null && item.created != "") {
          item.created = item.created.replace(/\./g, ':');
          file.created = moment(item.created).format('D.M.YYYY');
        }
        file.caseNumber = item.caseNumber;
        file.abstract = item.abstract;
        file.description = item.description;
        file.subject = item.subject;
        file.storageLocation = item.storageLocation;
        file.type = item.type.type;
        file.itemTitle = item.title;
        file.fileName = document.sahkeDocument.fileName;
        file.nativeId = document.sahkeDocument.nativeId;
        file.fileName = document.sahkeDocument.fileName;
        file.formatName = document.sahkeDocument.formatName;
        files.push(file);
      });
      return files;
    },
    hasValue(item) {
      return !(item === null || item === '' || item.length === 0);
    }
  },
  watch: {
    getFilteredItems(newVal, oldVal) {
      if (newVal && oldVal && newVal.length !== oldVal.length) {
        this.getItems();
      }
    }
  }
};
</script>
<style>
.pagination-button-disabled {
  color: gray !important;
  cursor: not-allowed !important;
}


</style>
