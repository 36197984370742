import '@mdi/font/css/materialdesignicons.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import fi from '@/vuetify/src/locale/fi';
import sv from 'vuetify/es5/locale/sv';
import en from 'vuetify/es5/locale/en';


Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
  },
  lang: {
    locales: { fi, en, sv },
    current: 'fi',
  },
});
