const newState = () => {
  return {
    cases: [],
    records: [],
    configuration: {
      sortBy: 'latest',
      type: 'cases',
      ministries: [],
      keywords: [],
      caseNumber: [],
      recordId: [],
      creationDateFrom: '',
      creationDateTo: '',
      status: 'Kaikki',
      pagination: '10',
      resultSource: '10',
      showCompilation: false,
      showMetaData: false,
      dataFields: ['id', 'title', 'created'],
      metaData: [],
      listingAs: 'automatic',
      selectedMinistries: [],
      searchMethod: 'free',
    },
    params: {},
    paramsProperties: ['type', 'caseNumber', 'recordId', 'ministries', 'keywords', 'creationDateFrom', 'creationDateTo', 'status', 'pagination', 'resultSource', 'dataFields', 'sortBy'],
    paramsUrl: '',
    filteredList: [],
    selectedList: [],
    isLoading: true,
    errored: false,
    recordsLoaded: false,
    ministriesAbbr: ['TEM', 'OKM', 'OM', 'VNK', 'VM', 'STM', 'LVM', 'UM', 'SM', 'VN'],
    ministries: [
      {
        title: 'Työ- ja elinkeinoministeriö (TEM)',
        abbr: 'TEM'
      }, {
        title: 'Opetus-ja Kulttuuriministeriö (OKM)',
        abbr: 'OKM'
      },
      {
        title: 'Oikeusministeriö (OM)',
        abbr: 'OM'
      }, {
        title: 'Valtioneuvoston kanslia (VNK)',
        abbr: 'VNK'
      },
      {
        title: 'Valtiovarainministeriö (VM)',
        abbr: 'VM'
      },
      {
        title: 'Sosiaali- ja terveysministeriö (STM)',
        abbr: 'STM'
      },
      {
        title: 'Liikenneministeriö (LVM)',
        abbr: 'LVM'
      },
      {
        title: 'Ulkoministeriö (UM)',
        abbr: 'UM'
      },
      {
        title: 'Sisäministeriö (SM)',
        abbr: 'SM'
      },
      {
        title: 'Valtioneuvosto (VN)',
        abbr: 'VN'
      }
    ],
    locale: 'fi',
    language: 'fi-fi',
    caseFields: ['created', 'status', 'keyword', 'description', 'abstract', 'id', 'organization', 'publisher', 'files', 'filename', 'type', 'format', 'nativeid', 'title'],
    recordFields: ['created', 'status', 'keyword', 'description', 'abstract', 'id', 'organization', 'publisher', 'files', 'filename', 'type', 'format', 'nativeid', 'title'],
    isConfigView: false,
    configUrl: '',
    apiUrl: '',
    env: null,
    imagePath: '',
    currentView: 'filter'
  };
};

export default newState;