import Vue from 'vue';
import axios from 'axios';
import vuetify from './plugins/vuetify';
import App from './App.vue';
import locales from './locales';
import router from './router';
import VueTranslate from 'vue-translate-plugin';
import newStore from "@/store/store";

Vue.use(VueTranslate);

Vue.config.productionTip = false;

document.addEventListener("DOMContentLoaded", () => {
  document.querySelectorAll('.vue_mount--vahva:not([data-v-app])').forEach((e) => {
    const mountEl = e;
    const parentEl = mountEl.parentElement;

    new Vue({
      router,
      vuetify,
      store: newStore(),
      locales,
      axios,
      render: createElement => {
        const context = {
          props: {...parentEl.dataset},
        };
        return createElement(App, context);
      }
    }).$mount(mountEl);
  });
});


