import Vue from 'vue';
import Router from 'vue-router';
import View from './views/View.vue';

Vue.use(Router);

export default new Router({
  //mode: 'history',
  routes: [
    {
      path: '/',
      name: 'view',
      component: View,
    },
    {
      path: '/p_yjagenericapplication_WAR_yjagenericportlet*',
      redirect: '/'
    },
    {
      path: '/configuration',
      name: 'configuration',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "configuration" */ './views/admin/Configuration.vue')
    },
    {
      path: '/asia/:id',
      name: 'asia',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "configuration" */ './views/Case.vue'),
      props: true
    },
    {
      path: '/asiakirja/:id',
      name: 'asiakirja',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "configuration" */ './views/Record.vue'),
      props: true
    }
  ]
});

