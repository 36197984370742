import Vuex from 'vuex';
import Vue from 'vue';

import newState from '@/store/vahva/state';
import actions from '@/store/vahva/actions';
import getters from '@/store/vahva/getters';
import mutations from '@/store/vahva/mutations';

Vue.use(Vuex);

const newStore = () => {
  return new Vuex.Store({
    state: newState(),
    actions,
    mutations,
    getters,
  });
};

export default newStore;