<template>
  <div>
    <v-row>
      <v-col cols="12">
        <label class="font-weight-bold subtitle-1" v-translate>Aineistojen nosto tapa</label>
        <v-radio-group v-model="listing" :mandatory="false">
          <v-radio
            id="listAutomatic"
            name="listingType"
            :label="t('Automaattisesti')"
            value="automatic"
            :checked="listing === 'automatic'"
            :aria-checked="listing === 'automatic'"
          ></v-radio>
          <v-radio
            id="listManual"
            name="listingType"
            :label="t('Manuaalisesti')"
            value="manually"
            :checked="listing === 'manually'"
            :aria-checked="listing === 'manually'"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="search"
          label="Haku"
          single-line
          hide-details
          class="mb-10"
          :disabled="getListingState"
          clearable
        ></v-text-field>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="getItems"
          :search="search"
          item-key="title"
          class="elevation-1"
          id="manuallySelectedDocuments"
          :value="setSelected"
          :show-select=showSelected
        ></v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  data: () => ({
    selectable: false,
    listing: 'manually',
    search: '',
    numOfHits: null,
    selected: [],
    headers: [{
      text: 'Numero',
      sortable: false,
      align: 'left',
      value: 'caseNumber'
    },
      {
        text: 'Otsikko',
        value: 'title'

      }, {
        text: 'Määrä',
        value: 'caseFileRelation'
      }
    ],
    items: [],
    async: false
  }),
  computed: {
    numberOfHits: function () {
      return this.items.length;
    },
    ...mapGetters({
      manuallySelected: 'getConfigurationManuallySelected',
      listingAs: 'getConfigurationListingAs',
      getItems: 'getFilteredItems',
      getLoadStatus: 'getLoadingStatus'
    }),
    ...mapActions({
      setFilters: 'resetFilters',
      getQuery: 'requestFromApi'
    }),
    showSelected: function () {
      this.selectable = this.listing === 'manually';
      return this.selectable;
    },
    setSelected: function () {
      if (this.selected !== undefined) {
        const items = this.selected.filter(item => item);
        if (items.length > 0) {
          const ids = [];
          Object.keys(items).forEach(item => ids.push(items[item].caseNumber));
          this.$store.dispatch('setConfigurationDataset', { key: 'caseNumber', value: ids });
        }
      }
    },
    getListingState: function () {
      return this.listing === 'automatic';
    }
  },
  methods: {
    getItemsValues() {
      return Object.values(this.getItems);
    }
  },
  mounted() {
    console.log('SELECTED_DOCUMENTS: listingAs', this.getListingState);
  }
};
</script>
