<template>
  <v-container>
    <div v-if="getLoadingStatus">
      <Loader />
    </div>
    <div v-else>
      <div v-show="async">
        <List />
      </div>
    </div>
  </v-container>
</template>
<script>
import List from '@/components/Lists';
import Loader from '@/components/Loader';

import {mapGetters} from 'vuex';

export default {
  components: {
    List,
    Loader
  },
  data: () => ({
    async: false
  }),
  computed: {
    ...mapGetters(['getLoadingStatus', 'getConfigurationData'])
  },
  async mounted() {
    this.getAsyncStatus();
  },
  methods: {
    getAsyncStatus() {
      return new Promise((resolve) => {
        const vm = this;
        setTimeout(function () {
          if (vm.$store.getters.getFilteredItems) {
            resolve();
          }
        }, 500);

      }).then(() => {
        this.async = true;
      }).catch(error => {
        console.log('error', error.message);
      })
    }
  }
}
</script>
