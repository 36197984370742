const getApiUrl = (state) => {
  return state.apiUrl;
};

const getImagePath = (state) => {
  return state.imagePath;
};

const getFilteredItems = (state) => {
  return state.filteredList;
};

const getSortOrder = (state) => {
  return state.configuration.sortBy;
};

const getLoadingStatus = (state) => {
  return state.isLoading;
};

const getAllMinistries = (state) => {
  const data = state.ministries.slice(0);
  data.sort((a, b) => {
    return a.title - b.title;
  });
  return data;
};

const getLocale = (state) => {
  return state.locale;
};

const getLanguage = (state) => {
  return state.language;
};

const getMetaFields = (state) => {
  if (state.configuration.type !== 'cases') {
    return state.recordFields;
  } else {
    return state.caseFields;
  }
};

const getConfigurationViewStatus = (state) => {
  return state.isConfigView;
};

const getData = (state) => {
  return state.data;
};

const getConfigurationData = (state) => {
  return state.configuration;
};

const getConfigurationUrl = (state) => {
  return state.configUrl;
};

const getConfigurationType = (state) => {
  return state.configuration.type;
};

const getConfigurationMinistry = (state) => {
  const selected = state.configuration.ministries;
  const ministries = state.ministries;

  const data = ministries.filter((item) => {
    return Object.values(item).some(val =>
      selected.includes(val)
    );
  });
  return data;

};

const getConfigurationMinistries = (state) => {
  return state.configuration.ministries;
};

const getConfigurationKeywords = (state) => {
  return state.configuration.keywords;
};

const getConfigurationCaseNumbers = (state) => {
  return state.configuration.caseNumber;
};

const getConfigurationcreatedDateFrom = (state) => {
  return state.configuration.creationdDateFrom;
};

const getConfigurationcreatedDateTo = (state) => {
  return state.configuration.creationDateTo;
};

const getConfigurationCaseStatus = (state) => {
  return state.configuration.status;
};

const getConfigurationPagination = (state) => {
  return state.configuration.pagination;
};

const getConfigurationResultSource = (state) => {
  return state.configuration.resultSource;
};

const getConfigurationShowCompilation = (state) => {
  return state.configuration.showCompilation;
};

const getConfigurationDataFields = (state) => {
  return state.configuration.dataFields;
};

const getConfigurationMetaData = (state) => {
  return state.configuration.metaData;
};

const getConfigurationListingAs = (state) => {
  return state.configuration.listingAs;
};

const getConfigurationSelected = (state) => {
  return state.configuration.selectedMinistries;
};

const getConfigurationSearchMethod = (state) => {
  return state.configuration.searchMethod;
};

const getConfigurationManuallySelected = (state) => {
  return state.configuration.manuallySelected;
};

const getLoadingError = (state) => {
  return state.errored;
};

const getStaticMinistries = (state) => {
  return state.ministries;
};

const getCases = (state) => {
  return state.cases;
};

const getParams = (state) => {
  return state.params;
};

const getParamsUrl = (state) => {
  return state.paramsUrl;
};

const getActiveView = (state) => {
  return state.currentView;
};

export default {
  getActiveView,
  getApiUrl,
  getImagePath,
  getFilteredItems,
  getSortOrder,
  getLoadingStatus,
  getAllMinistries,
  getLocale,
  getLanguage,
  getMetaFields,
  getConfigurationViewStatus,
  getData,
  getConfigurationData,
  getConfigurationUrl,
  getConfigurationType,
  getConfigurationMinistry,
  getConfigurationMinistries,
  getConfigurationKeywords,
  getConfigurationCaseNumbers,
  getConfigurationcreatedDateFrom,
  getConfigurationcreatedDateTo,
  getConfigurationCaseStatus,
  getConfigurationPagination,
  getConfigurationResultSource,
  getConfigurationShowCompilation,
  getConfigurationDataFields,
  getConfigurationMetaData,
  getConfigurationListingAs,
  getConfigurationSelected,
  getConfigurationManuallySelected,
  getConfigurationSearchMethod,
  getLoadingError,
  getCases,
  getParams,
  getParamsUrl,
  getStaticMinistries
};
