const fi = {
  close: 'Sulje',
  dataIterator: {
    noResultsText: 'Ei löytynyt samankaltaisia kohteita',
    loadingText: 'Haetaan...',
  },
  dataTable: {
    itemsPerPageText: 'Rivejä sivulla:',
    ariaLabel: {
      sortDescending: ': Laskeva järjestys. Klikkaa poistaaksesi järjestelyn.',
      sortAscending: ': Nouseva järjestys. Klikkaa muuttaaksesi järjestys.',
      sortNone: ': Ei järjestetty. Klikkaa muuttaaksesi järjestys nousevaksi.',
    },
  },
  dataFooter: {
    itemsPerPageText: 'Kohteita sivulla:',
    itemsPerPageAll: 'Kaikki',
    nextPage: 'Seuraava sivu',
    prevPage: 'Edellinen sivue',
    firstPage: 'Ensimmäinen sivu',
    lastPage: 'Viimeinen sivu',
    pageText: 'Näytetään {0}-{1} / {2}',
  },
  datePicker: {
    itemsSelected: '{0} valittu',
  },
  noDataText: 'Ei dataa saatavilla',
  carousel: {
    prev: 'Edellinen näkymä',
    next: 'Seuraava näkymä',
  },
  calendar: {
    moreEvents: '{0} lisää'
  },
  fileInput: {
    counter: '{0} tiedostoa',
    counterSize: '{0} tiedostoa ({1} tiedostoista)'
  },
  timePicker: {
    am: 'AM',
    pm: 'PM'
  }
};

export default fi;

