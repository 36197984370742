import axios from 'axios';

const setApiUrl = (context, payload) => {
  context.commit('SET_API_URL', payload);
};

const setImagePath = (context, payload) => {
  context.commit('SET_IMAGE_PATH', payload);
};

const createFilteredList = (context) => {
  context.commit('CREATE_FILTERED_LIST');
};

const filterByDate = (context) => {
  context.commit('FILTER_ITEMS_BY_DATE');
};

const setLoadingStatus = (context) => {
  context.commit('SET_LOADING');
};

const setLocale = (context, payload) => {
  context.commit('SET_LOCALE', payload);
};

const setLanguage = (context, payload) => {
  context.commit('SET_LANGUAGE', payload);
};

const setConfigViewStatus = (context, payload) => {
  context.commit('SET_CONFIG_VIEW_STATUS', payload);
};

const setLoadingState = (context, payload) => {
  context.commit('SET_LOADING', payload);
};

const setData = (context, payload) => {
  axios
    .get(payload)
    .then(response => {
      context.commit('SET_DATA', response.data.cases);
    })
    .catch(() => {
      context.commit('SET_ERRORED', true);
    })
    .finally(() => {
      context.commit('SET_LOADING', false);
    });
};

const setJsonData = (context, payload, dispatch, state) => {
  const url = `${payload.url}/vahva/${payload.container}?${payload.params}`;

  axios
    .get(url)
    .then(response => {
      const container = Object.keys(response.data)[0];
      context.commit('SET_DATA', { 'data': response.data[container], 'key': payload.container });
      context.dispatch('resetFilters');
    })
    .catch(error => {
      console.log(error);
      context.commit('SET_ERRORED', true);
    })
    .finally(() => {
      context.commit('SET_LOADING', false);
    });
};


/* Default filters */
const clearFilters = ({ commit }) => {
  return new Promise((resolve) => {
    commit('RESET_FILTERED_LIST');
    resolve();
  });
};

const setFilteredList = (context, payload) => {
  if (Array.isArray(payload)) {
    context.commit('SET_FILTERED_LIST', payload);
  }
};

const filterByMinistry = ({ commit, dispatch, state }) => {
  return new Promise((resolve) => {
    console.log('after ministry filtering', state);
    commit('FILTER_BY_MINISTRY');
    resolve();
  });
};

const filterByCaseNumber = ({ commit }) => {
  return new Promise((resolve) => {
    commit('FILTER_BY_CASE_NUMBER');
    resolve();
  });
};

const filterByKeywords = ({ commit }) => {
  return new Promise((resolve) => {
    commit('FILTER_BY_KEYWORDS');
    resolve();
  });
};

const filterByDateRange = ({ commit }) => {
  return new Promise((resolve) => {
    commit('FILTER_BY_DATE');
    resolve();
  });
};

const filterByState = ({ commit }) => {
  return new Promise((resolve) => {

    commit('FILTER_BY_STATE');
    resolve();
  });
};

const sendQuery = ({ dispatch, commit, state }) => {
  dispatch('setParams');
  const type = state.configuration.type;
  let url = '';

  const paramsUrl = Object.entries(state.params).map(([key, val]) => `${key}=${val}`).join('&');
  dispatch('setParamsUrl', paramsUrl);

  if (type === 'records' && (state.params.recordId !== undefined && state.params.recordId.length === 1)) {
    url = `${state.apiUrl}/vahva/${type}/${state.params.recordId}/${state.configuration.resultSource}`;
  } else {
    url = `${state.apiUrl}/vahva/${type}?${state.paramsUrl}`;
  }

  axios
    .get(url)
    .then(response => {
      const container = Object.keys(response.data)[0];
      commit('SET_DATA', { 'data': response.data[container], 'key': state.configuration.type });
      if ((response.data.documents === undefined || response.data.documents === null || response.data.documents.length < 1)
        && (response.data.cases === undefined || response.data.cases === null || response.data.cases.length < 1)) {
        const dataTableEmptyWrapper = document.getElementsByClassName('v-data-table__empty-wrapper');
        if (dataTableEmptyWrapper[0] !== undefined) {
          const resultTd = dataTableEmptyWrapper[0].getElementsByTagName('td')[0];
          if (resultTd !== undefined) {
            resultTd.textContent = 'Ei tuloksia';
          }
        }
      } else {
        dispatch('createFilteredList');
      }
    })
    .catch(error => {
      console.log(error.message);
    });
};

const queryRecordsById = ({ dispatch, commit, state }) => {
  const arrays = state.params.recordId;
  const type = state.configuration.type;
  let container = 'cases';
  if (state.configuration.type === 'records') {
    container = 'documents';
  }

  const url = `${state.apiUrl}/vahva/${type}/`;
  const data = [];
  let index = 0;
  new Promise((resolve) => {
    arrays.forEach((array) => {
      axios
        .get(`${url}${array}/${state.configuration.resultSource}`)
        .then(response => {
          index++;
          data.push(response.data[container][0]);

          if (index >= arrays.length) {
            setTimeout(() => {
              commit('ADD_TO_DATAFIELD', { data, 'key': state.configuration.type });
              resolve();
            }, 500);
          }
        })
        .catch(error => {
          console.log('Error while querying single record', error.message);
        });
    });
  }).then(() => {
    setTimeout(() => {
      dispatch('createFilteredList');
    }, 2000);
  });

};


const resetFilters = ({ state, dispatch }) => {
  const clearFilters = dispatch('clearFilters');
  return Promise.all([clearFilters])
    .then(() => {
      if (state.configuration.type === 'records' && (state.configuration.recordId && state.configuration.length > 1)) {
        dispatch('queryRecordsById');
      } else {
        dispatch('sendQuery');
      }

    })
    .catch(error => {
      console.log(error.message);
    });
};

/* CONGIG */
const setConfigSortBy = (context, payload) => {
  context.commit('SET_CONFIG_SORTBY', payload);
};

const setConfigType = (context, payload) => {
  context.commit('SET_CONFIG_TYPE', payload);
};

const setConfigMinistries = (context, payload) => {
  context.commit('SET_CONFIG_SELECTED_MINISTRIES', payload);
  const items = payload.value;
  items.filter((item) => {
    return Object.keys(item).some(val => {
      if (val === 'abbr') {
        context.commit('SET_CONFIG_MINISTRY', item[val]);
      }
    });
  });
};

const setConfigKeywords = (context, payload) => {
  context.commit('SET_CONFIG_KEYWORDS', payload);
};

const setConfigCaseNumbers = (context, payload) => {
  context.commit('SET_CONFIG_CASE_NUMBERS', payload);
};

const setConfigCreatedDateFrom = (context, payload) => {
  context.commit('SET_CONFIG_CREATED_DATE_FROM', payload);
};

const setConfigCreatedDateTo = (context, payload) => {
  context.commit('SET_CONFIG_CREATED_DATE_TO', payload);
};

const setConfigCaseStatus = (context, payload) => {
  context.commit('SET_CONFIG_CASE_STATUS', payload);
};

const setConfigPagination = (context, payload) => {
  context.commit('SET_CONFIG_PAGINATION', payload);
};

const setConfigResultSource = (context, payload) => {
  context.commit('SET_CONFIG_RESULT_SOURCE', payload);
};

const setConfigShowCompilation = (context, payload) => {
  context.commit('SET_CONFIG_SHOW_COMPILATION', payload);
};

const setConfigurationVisibleDataFields = (context, payload) => {
  context.commit('SET_CONFIGURATION_VISIBLE_DATA', payload);
};

const setConfigMetaData = (context, payload) => {
  context.commit('SET_CONFIG_META_DATA', payload);
};

const setConfigShowMetaData = (context, payload) => {
  context.commit('SET_CONFIG_SHOW_META_DATA', payload);
};

const setConfigSearchMethod = (context, payload) => {
  context.commit('SET_CONFIG_SEARCH_METHOD', payload);
};

const setConfigurationUrl = (context, payload) => {
  context.commit('SET_CONFIG_URL', payload);
};

const setConfigurationData = (context, payload) => {
  if (Object.keys(payload).length > 0) {
    context.commit('SET_CONFIGURATION_DATA', payload);
  }

  // Always set case status filter to include all.
  context.commit('SET_CONFIG_CASE_STATUS', 'Kaikki');
  context.commit('SET_CONFIG_SHOW_COMPILATION', false);
};

const setConfigurationListingAs = (context, payload) => {
  context.commit('SET_CONFIGURATION_LISTING_AS', payload);
};

const setConfigurationSelected = (context, payload) => {
  context.commit('SET_CONFIGURATION_SELECTED', payload);
};

const setConfigurationDataset = (context, payload) => {
  context.commit('SET_CONFIGURATION_DATASET', payload);
};

const setParams = (context) => {
  context.commit('SET_PARAMS');
};

const setParamsUrl = (context, payload) => {
  context.commit('SET_PARAMS_URL', payload);
};

const setManuallySelected = (context, payload) => {
  if (Object.keys(payload).length > 0) {
    const ids = [];
    Object.keys(payload).forEach(item => ids.push(payload[item].caseNumber));
    context.commit('SET_MANUALLY_SELECTED', ids);
  }

  context.commit('SET_SELECTED_LIST', payload);
};

const setSelected = (context, payload) => {
  context.commit('SET_SELECTED_LIST', payload);
};

const removeByIndex = (context, payload) => {
  context.commit('REMOVE_BY_INDEX', payload);
};

const setActiveView = (context, payload) => {
  context.commit('SET_ACTIVE_VIEW', payload);
};


export default {
  setActiveView,
  setApiUrl,
  setImagePath,
  setFilteredList,
  createFilteredList,
  filterByDate,
  setLoadingStatus,
  setLocale,
  setLanguage,
  setConfigViewStatus,
  setData,
  setConfigSortBy,
  setConfigType,
  setConfigMinistries,
  setConfigKeywords,
  setConfigCaseNumbers,
  setConfigCreatedDateFrom,
  setConfigCreatedDateTo,
  setConfigCaseStatus,
  setConfigPagination,
  setConfigResultSource,
  setConfigShowCompilation,
  setConfigurationVisibleDataFields,
  setConfigMetaData,
  setConfigShowMetaData,
  setConfigSearchMethod,
  setConfigurationUrl,
  setConfigurationData,
  setConfigurationListingAs,
  setConfigurationSelected,
  setJsonData,
  queryRecordsById,
  setConfigurationDataset,
  resetFilters,
  clearFilters,
  filterByMinistry,
  filterByCaseNumber,
  filterByKeywords,
  filterByDateRange,
  filterByState,
  setParams,
  setParamsUrl,
  sendQuery,
  setManuallySelected,
  setLoadingState,
  removeByIndex,
  setSelected
};
