<template>
  <v-container>
    <v-card
      max-width="100%"
      width="1185"
      class="mx-auto"
    >
      <v-card-title>

        <v-tabs>
          <v-tab v-on:click="setActiveView('filter')">
            <span v-translate>Rajaa aineistoa</span>
          </v-tab>
          <v-tab v-on:click="setActiveView('look-and-feel')">
            <span v-translate>Esitystyyli ja -asetukset</span>
          </v-tab>
        </v-tabs>
      </v-card-title>
      <v-card-text>
        <div v-show="getActiveView === 'filter'">
          <Filters />
        </div>

        <div v-show="getActiveView === 'look-and-feel'">
          <LookAndFeel />
        </div>
        <v-alert
          type="success"
          v-model="formSent"
          dismissible
        >
          <span v-translate>Tiedot tallennettu onnistuneesti</span>
        </v-alert>
        <v-alert
          type="error"
          v-model="formSentError"
          dismissible
        >
          <span v-translate>Jokin meni pieleen tallennuksessa</span>
        </v-alert>
      </v-card-text>

      <v-card-actions class="grey lighten-2">
        <v-col>
          <v-btn color="primary" x-large right @click="saveConfigurationDataToInput">
            <span v-translate>Tallenna</span>
          </v-btn>
          <span class="saving-reminder">Muista tallentaa tiedot myös "{{getViewString}}" -välilehdeltä</span>
        </v-col>

      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import Filters from '@/components/admin/Filters';
import SelectDocuments from '@/components/admin/SelectDocuments';
import LookAndFeel from '@/components/admin/LookAndFeel';


export default {
  components: {
    Filters,
    LookAndFeel
  },
  data: () => ({
    formSent: false,
    formSentError: false
  }),
  mounted() {
    this.$nextTick(() => {
      // The whole view is rendered, so we can safely access or query

    });
  },
  computed: {
    ...mapGetters({
      configurationData: 'getConfigurationData',
      configurationUrl: 'getConfigurationUrl',
      params: 'getParams',
      getCaseNumber: 'getConfigurationCaseNumbers',
      getActiveView: 'getActiveView'
    }),
    getViewString() {
      const activeView = this.getActiveView;
      if (activeView === 'filter') {
        return 'Esitystyyli ja -asetukset';
      }
      return 'Rajaa aineistoa';
    }
  },
  methods: {
    ...mapActions({
      setActiveView: 'setActiveView'
    }),
    returnId(items, id) {
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.id.includes(id)) {
          return item;
        }
      }
    },
    saveConfigurationDataToInput() {
      const vm = this;
      const container = document.getElementById('app-selection');
      console.log('just before saving: ', this.getCaseNumber);
      if (container) {
        const form = container.querySelectorAll('form')[0];
        const fields = form.querySelectorAll('input[type="hidden"]');
        const field = this.returnId(fields, 'applicationConfiguration');
        console.log('saving', JSON.stringify(vm.configurationData), 'original: ', vm.configurationData);
        field.value = JSON.stringify(vm.configurationData);
        form.submit();
      }
    },

  }

};
</script>
<style>
.saving-reminder {
  margin-left: 3rem;
}
</style>
