<template>
  <div>
    <v-row>
      <v-col cols="12">
        <label class="font-weight-bold subtitle-1">
          <span v-translate>Sivutus</span>
          <v-chip color="default" class="ml-2">
            <span v-if="paginationType !== '0'">
              {{ paginationType }}
            </span>
            <span v-else>
             {{ t('Ei käytössä') }}
            </span>
          </v-chip>
        </label>
        <v-radio-group v-model="paginationType" :mandatory="false">
          <v-radio
            name="paginationMode"
            id="selectUsePaginationNone"
            :label="t('Ei käytössä')"
            :checked="paginationType === '0'"
            :aria-checked="paginationType === '0'"
            value="0"

          ></v-radio>
          <v-radio
            name="paginationMode"
            id="selectUsePagination10"
            label="10"
            value="10"
            :checked="paginationType === '10'"
            :aria-checked="paginationType === '10'"

          ></v-radio>
          <v-radio
            name="paginationMode"
            id="selectUsePagination20"
            label="20"
            value="20"
            :checked="paginationType === '20'"
            :aria-checked="paginationType === '20'"

          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <label class="font-weight-bold subtitle-1">
          <span v-translate>Tulosten lähde</span>
          <v-chip color="default" class="ml-2">
            <span v-if="resultSourceType === '10'">
              {{ t('Vain aktiivipuolelta') }}
            </span>
            <span v-else-if="resultSourceType === '20'">
              {{ t('Vain arkistosta') }}
            </span>
            <span v-else>
              {{ t('Kaikki') }}
            </span>
          </v-chip>
        </label>
        <v-radio-group v-model="resultSourceType" :mandatory="false">
          <v-radio
                  name="resultSourceMode"
                  id="selectFilesAll"
                  :label="t('Kaikki')"
                  :checked="resultSourceType === '0'"
                  :aria-checked="resultSourceType === '0'"
                  value="0"

          ></v-radio>
          <v-radio
                  name="resultSourceMode"
                  id="selectFilesActive"
                  :label="t('Vain aktiivipuolelta')"
                  value="10"
                  :checked="resultSourceType === '10'"
                  :aria-checked="resultSourceType === '10'"

          ></v-radio>
          <v-radio
                  name="resultSourceMode"
                  id="selectFilesArchive"
                  :label="t('Vain arkistosta')"
                  value="20"
                  :checked="resultSourceType === '20'"
                  :aria-checked="resultSourceType === '20'"

          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <label class="font-weight-bold subtitle-1" v-translate>
          Asiakirjojen järjestys
        </label>
        <p class="" v-translate>
          Valitse sopiva asiakirjojen järjestys
        </p>
        <v-radio-group v-model="sortBy" :mandatory="false">
          <v-radio
            id="sortByLatest"
            name="sorting_order"
            :label="t('Uusimmat')"
            value="latest"
            v-on:change="updateSortBy('latest')"
            :checked="sortBy === 'latest'"
            :aria-checked="sortBy === 'latest'"
          ></v-radio>
          <v-radio
            id="sortByAlphabetical"
            name="sorting_order"
            value="alphabetical"
            :label="t('Aakkosjärjestyksessä')"
            v-on:change="updateSortBy('alphabetical')"
            :checked="sortBy === 'alphabetical'"
            :aria-checked="sortBy === 'alphabetical'"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" v-if="getType === 'cases'">
        <label class="font-weight-bold subtitle-1" for="field_selectDetailsToShow" v-translate>
          Valitse asiasta näytettävät tiedot
        </label>
        <p id="hint_selectDetailsToShow" v-translate>
          Valitse mitkä tiedot asian yhteydessä näytetään. Tiedot esitetään ennen asiakirjalistausta. Asian alla
          listataan sen sisältämät asiakirjat.
          Ruksista voit poistaa kaikki valinnat.
        </p>
        <v-combobox
          id="field_selectDetailsToShow"
          aria-describedby="hint_selectDetailsToShow"
          v-model="selectionDetailsToShow"
          :items="metaFields"
          :item-text="metaFields"
          outlined
          multiple
          chips
          deletable-chips
          clearable
          return-object
          @change="setConfigShowCompilationData(selectionDetailsToShow)"
          :menu-props="{'maxHeight': 800}"
        >
          <template slot="item" slot-scope="data">
            {{ t(data.item) }}
          </template>
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
              v-bind="attrs"
              :input-value="selected"
              color="primary"
              close
              @click="select "
              @click:close="removeDetailsOption(item)"
            >
              <strong>
                {{ t(item) }}
              </strong>
            </v-chip>
          </template>
        </v-combobox>
      </v-col>
      <v-col cols="6">
        <label class="font-weight-bold subtitle-1" for="field_selectMetaDetailsToShow" v-translate>
          Asiakirjan metatiedot
        </label>
        <p id="hint_selectMetaDetailsToShow" v-translate>
          Valitse näytettävät metatiedot. Tiedot sisältävät aina asiakirjan otsikon.
          Huomioithan, että yksi asiakirja voi koostua useista tiedostoista.
          Ruksista voit poistaa kaikki valinnat.
        </p>
        <v-combobox
          id="field_selectMetaDetailsToShow"
          aria-describedby="hint_selectDetailsToShow"
          v-model="selectionMetaDetailsToShow"
          :items="metaFields"
          outlined
          multiple
          chips
          deletable-chips
          clearable
          @change="setConfigVisibleMetaData(selectionMetaDetailsToShow)"
          :menu-props="{'maxHeight': 800}"
        >
          <template slot="item" slot-scope="data">
            {{ t(data.item) }}
          </template>
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
              v-bind="attrs"
              :input-value="selected"
              color="primary"
              close
              @click="select"
              @click:close="removeMetaDetailsOption(item)"
            >
              <strong>
                {{ t(item) }}
              </strong>
            </v-chip>
          </template>
        </v-combobox>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => ({
    selectionDetailsToShow: [],
    selectionMetaDetailsToShow: [],
    detailsOptions: [],

  }),
  mounted() {
    this.$nextTick(() => {

      this.selectionDetailsToShow = this.compilationData;
      this.selectionMetaDetailsToShow = this.compilationMetaData;
      this.setConfigShowCompilation(false);
      this.resetFilters();
    });
  },
  methods: {
    ...mapActions({
      setConfigShowCompilation: 'setConfigShowCompilation',
      resetFilters: 'resetFilters',
      setConfigPagination: 'setConfigPagination',
      setConfigResultSource: 'setConfigResultSource',
      setConfigSortBy: 'setConfigSortBy',
      setConfigurationVisibleDataFields: 'setConfigurationVisibleDataFields',
      setConfigMetaData: 'setConfigMetaData',

    }),
    updatePagination(val) {
      this.setConfigPagination(val);
    },
    updateResultSource(val) {
      this.setConfigResultSource(val);
    },
    updateSortBy(val) {
      this.setConfigSortBy(val);
      this.$store.dispatch('resetFilters');
    },
    setConfigShowCompilationData(val) {
      this.setConfigurationVisibleDataFields(val);
      this.$store.dispatch('resetFilters');
    },
    setConfigVisibleMetaData(val) {
      this.setConfigMetaData(val);
      this.$store.dispatch('resetFilters');
    },
    removeDetailsOption(item) {
      this.selectionDetailsToShow.splice(this.selectionDetailsToShow.indexOf(item), 1);
      this.selectionDetailsToShow = [...this.selectionDetailsToShow];
      this.setConfigurationVisibleDataFields(this.selectionDetailsToShow);
      this.$store.dispatch('resetFilters');
    },
    removeMetaDetailsOption(item) {
      this.selectionMetaDetailsToShow.splice(this.selectionMetaDetailsToShow.indexOf(item), 1);
      this.selectionMetaDetailsToShow = [...this.selectionMetaDetailsToShow];
      this.setConfigMetaData(this.selectionMetaDetailsToShow);

      this.$store.dispatch('resetFilters');
    }
  },
  computed: {
    ...mapGetters({
      getType: 'getConfigurationType',
      metaFields: 'getMetaFields',
      getConfigurationPagination: 'getConfigurationPagination',
      getConfigurationResultSource: 'getConfigurationResultSource',
      sorting: 'getSortOrder',
      compilationData: 'getConfigurationDataFields',
      compilationMetaData: 'getConfigurationMetaData',
      getLoadStatus: 'getLoadingStatus',
      getConfigType: 'getConfigurationType'
    }),
    paginationType: {
      get() {
        return this.getConfigurationPagination;
      },
      set(val) {
        this.updatePagination(val)
      }
    },
    resultSourceType: {
      get() {
        return this.getConfigurationResultSource;
      },
      set(val) {
        this.updateResultSource(val)
      }
    },
    sortBy: {
      get() {
        return this.sorting;
      },
      set(val) {
        this.updateSortBy(val);
      }
    },
    getType: function () {
      return this.getConfigType;
    }
  }
};
</script>
