const locales = {
  'fi': {
    'titles.latest': 'Uusimmat',
    'keyword': 'Asiasana',
    'created': 'Laatimisaika',
    'status': 'Tila',
    'description': 'Kuvailu',
    'organization': 'Organisaatio',
    'id': 'Asianumero',
    'creator': 'Laatija',
    'category': 'Kategoria',
    'filetypes': 'Tiedostomuodot',
    'publisher': 'Julkaisija',
    'title': 'Otsikko',
    'abstract': 'Lyhennelmä',
    'files': 'Dokumentit',
    'type': 'Tyyppi',
    'yes': 'Kyllä',
    'no': 'Ei',
    'nativeid': 'NativeID',
    'format': 'Tiedostotyyppi',
    'filename': 'Tiedostonimi'
  },
  'en': {
    'titles.latest': 'Latest',
    'Edellinen': 'Previous',
    'Seuraava': 'Next'
  },
  'sv': {
    'Edellinen': 'Förra',
    'Seuraava': 'Nästa'
  }
};

export default locales;
