const SET_API_URL = (state, payload) => {
  state.apiUrl = payload;
};

const SET_IMAGE_PATH = (state, payload) => {
  state.imagePath = payload;
};

const SET_FILTERED_LIST = (state, payload) => {
  state.filteredList = [...[], ...payload];
};

const RESET_FILTERED_LIST = (state) => {
  state.filteredList = state[state.configuration.type];
};

const FILTER_BY_MINISTRY = (state) => {
  /*
  const filtered = state.filteredList
  let ministries = state.configuration.ministries
  let selectedMinistries = state.configuration.selectedMinistries;
  let data = []

  if (ministries.length != 0) {

      ministries.forEach(s => {
          Object.keys(filtered).forEach(key => {
                  let term = filtered[key]['caseNumber'].split('/').slice(0);
                  if (term[0].includes(s))  return data[key] = filtered[key]
          })
      })

      if (selectedMinistries.length === 0) {
          selectedMinistries = ministries
      }

      state.filteredList = data
  }

  return state.filteredList
  */
};

const FILTER_BY_CASE_NUMBER = (state) => {
  /*
  let filtered  = state.filteredList
  if ( Object.keys(state.configuration.caseNumbers).length > 0) {
      const numbers = state.configuration.caseNumbers.map(s => s.trim().toLowerCase());
      let data = []

      numbers.forEach(s => {
        Object.keys(filtered).forEach(key => {
              if (filtered[key]['caseNumber'].toLowerCase().includes(s)) {
                 return data[key] = filtered[key]
              }
          })
      })

      state.filteredList = data
  }

  return state.filteredList
  */
};

const FILTER_BY_KEYWORDS = (state) => {
  /*
  let filtered  = state.filteredList
  const keywords = state.configuration.keywords.map(s => s.toLowerCase())
  let data = {}
  if (keywords.length != 0) {
      keywords.forEach(s => {
           data  = filtered.filter(o => Object.keys(o).some(k => {
              if (typeof o[k] === 'string')  return o[k].toLowerCase().includes(s)
          }))
      })
      state.filteredList = data
  }
  return state.filteredList
  */
};

const FILTER_BY_STATE = (state) => {
  /*
  const itemState = state.configuration.status

  if (itemState.length != 0 && itemState !== 'Kaikki') {
      state.filteredList = state.filteredList.filter(item => item['status'].includes(itemState))
  }

  return state.filteredList
  */
};

const FILTER_BY_DATE = (state) => {
  /*
  let items = state.filteredList

  let startDate = state.configuration.creationDateFrom
  let endDate = state.configuration.creationDateTo

  if (startDate === "") {
      startDate = moment('1900-01-01').toISOString();
  }

  if (endDate === "") {
      endDate = moment().toISOString();
  }

  state.filteredList = items.filter(function(item){
      return item.created > startDate && item.created < endDate
  });

  return state.filteredList
  */
};

const CREATE_FILTERED_LIST = (state) => {
  const type = state.configuration.type;
  state.filteredList = state[type];
};

const SET_LOADING = (state, payload) => {
  state.isLoading = payload;
};

const SET_ERRORED = (state, payload) => {
  state.errored = payload;
};

const SET_LOCALE = (state, payload) => {
  state.locale = payload;
};

const SET_LANGUAGE = (state, payload) => {
  state.language = payload;
};

const SET_CONFIG_VIEW_STATUS = (state, payload) => {
  state.isConfigView = payload;
};

const SET_DATA = (state, { data, key }) => {
  state[key] = data;
};

const SET_CONFIGURATION_DATASET = (state, payload) => {
  Object.keys(state.configuration).forEach(key => {
    if (key === payload.key) {
      state.configuration[key] = payload.value;
    }
  });
};

const ADD_TO_DATAFIELD = (state, { data, key }) => {
  Object.keys(data).forEach(item => {
    if (state[key].length > 0) {
      const entry = state[key].filter(s => s.nativeId === data[item].nativeId);
      if (entry.length === 0) {
        state[key].push(data[item]);
        state.selectedList.push(data[item]);
      }
    } else {
      state[key].push(data[item]);
      state.selectedList.push(data[item]);
    }

  });
};

const SET_SELECTED_LIST = (state, payload) => {
  state.selectedList = payload;
};

/* CONFIG */
const SET_CONFIG_SORTBY = (state, payload) => {
  state.configuration.sortBy = payload;
};

const SET_CONFIG_TYPE = (state, payload) => {
  state.configuration.type = payload;
};

const SET_CONFIG_SELECTED_MINISTRIES = (state, payload) => {
  state.configuration.selectedMinistries = payload;
};

const SET_CONFIG_MINISTRY = (state, payload) => {
  if (!state.configuration.ministries.includes(payload)) {
    return state.configuration.ministries.push(payload);
  }
};

const SET_CONFIG_KEYWORDS = (state, payload) => {
  state.configuration.keywords = payload;
};

const SET_CONFIG_CASE_NUMBERS = (state, payload) => {
  state.configuration.caseNumber = payload.filter(item => item !== '' && item != null);
};

const SET_CONFIG_CREATED_DATE_FROM = (state, payload) => {
  state.configuration.creationDateFrom = payload;
};

const SET_CONFIG_CREATED_DATE_TO = (state, payload) => {
  state.configuration.creationDateTo = payload;
};

const SET_CONFIG_CASE_STATUS = (state, payload) => {
  state.configuration.status = payload;
};

const SET_CONFIG_PAGINATION = (state, payload) => {
  state.configuration.pagination = payload;
};

const SET_CONFIG_RESULT_SOURCE = (state, payload) => {
  state.configuration.resultSource = payload;
};

const SET_CONFIG_SHOW_COMPILATION = (state, payload) => {
  state.configuration.showCompilation = payload;
};

const SET_CONFIGURATION_VISIBLE_DATA = (state, payload) => {
  state.configuration.dataFields = payload;
};

const SET_CONFIG_META_DATA = (state, payload) => {
  state.configuration.metaData = payload;
};

const SET_CONFIG_SHOW_META_DATA = (state, payload) => {
  state.configuration.showMetaData = payload;
};

const SET_CONFIG_URL = (state, payload) => {
  state.configuration.rl = payload;
};

const SET_CONFIG_SEARCH_METHOD = (state, payload) => {
  state.configuration.searchMethod = payload;
};

const SET_CONFIGURATION_DATA = (state, payload) => {
  state.configuration = payload;
};

const SET_CONFIGURATION_LISTING_AS = (state, payload) => {
  state.configuration.listingAs = payload;
};

const SET_CONFIGURATION_SELECTED = (state, payload) => {
  state.configuration = Object.assign({}, payload);
};

const SET_ENV = (state, payload) => {
  state.env = payload;
};

const SET_MANUALLY_SELECTED = (state, payload) => {
  if (payload.filter(item => item)) {
    state.configuration.caseNumber = payload;
  }
};

const REMOVE_BY_INDEX = (state, payload) => {
  state.configuration[payload.key].splice(payload.index, 1);
};

const SET_PARAMS = (state) => {
  const config = state.configuration;
  const data = Object.keys(config)
    .filter(key => state.paramsProperties.includes(key))
    .reduce((item, key) => {
      if (typeof config[key] === 'string') {
        if (config[key] !== '') {
          if (key === 'status' || key === 'keywords') {
            item[key] = encodeURIComponent(config[key]);
          } else {
            item[key] = config[key];
          }
        }
      } else if (typeof config[key] === 'object') {
        if (Object.keys(config[key]).length > 0) {
          if (key === 'status') {
            item[key] = encodeURIComponent(config[key]);
          } else {
            item[key] = config[key];
          }
        }
      } else {
        item[key] = config[key];
      }

      return item;
    }, {});
  state.params = data;
};

const SET_PARAMS_URL = (state, payload) => {
  state.paramsUrl = payload;
};

const SET_ACTIVE_VIEW = (state, payload) => {
  state.currentView = payload;
};

export default {
  SET_ACTIVE_VIEW,
  ADD_TO_DATAFIELD,
  SET_FILTERED_LIST,
  SET_API_URL,
  SET_IMAGE_PATH,
  RESET_FILTERED_LIST,
  FILTER_BY_MINISTRY,
  FILTER_BY_CASE_NUMBER,
  FILTER_BY_KEYWORDS,
  FILTER_BY_DATE,
  FILTER_BY_STATE,
  CREATE_FILTERED_LIST,
  SET_LOADING,
  SET_ERRORED,
  SET_LOCALE,
  SET_LANGUAGE,
  SET_CONFIG_VIEW_STATUS,
  SET_DATA,
  SET_CONFIG_SORTBY,
  SET_CONFIG_TYPE,
  SET_CONFIG_SELECTED_MINISTRIES,
  SET_CONFIG_MINISTRY,
  SET_CONFIG_KEYWORDS,
  SET_CONFIG_CASE_NUMBERS,
  SET_CONFIG_CREATED_DATE_FROM,
  SET_CONFIG_CREATED_DATE_TO,
  SET_CONFIG_CASE_STATUS,
  SET_CONFIG_PAGINATION,
  SET_CONFIG_RESULT_SOURCE,
  SET_CONFIG_SHOW_COMPILATION,
  SET_CONFIG_SHOW_META_DATA,
  SET_CONFIGURATION_VISIBLE_DATA,
  SET_CONFIG_META_DATA,
  SET_CONFIG_URL,
  SET_CONFIG_SEARCH_METHOD,
  SET_CONFIGURATION_DATA,
  SET_CONFIGURATION_LISTING_AS,
  SET_CONFIGURATION_SELECTED,
  SET_CONFIGURATION_DATASET,
  SET_PARAMS,
  SET_PARAMS_URL,
  SET_ENV,
  SET_MANUALLY_SELECTED,
  REMOVE_BY_INDEX,
  SET_SELECTED_LIST,
};
