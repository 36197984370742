<template>
  <v-app>
    <v-content>
      <div v-if="getErrorStatus">
        <v-alert
          border="left"
          colored-border
          type="error"
          elevation="2"
          class="ml-auto mr-auto mt-10"
          width="75%"
        >
          <span v-translate>Jotain meni vikaan haettaessa dataa.</span>
        </v-alert>
      </div>
      <div v-if="getLoadStatus">
        <Loader />
      </div>
      <div v-show="!getErrorStatus && !getLoadStatus" class="portlet-vahva">
        <router-view />
      </div>
    </v-content>
  </v-app>
</template>

<script>
import Loader from '@/components/Loader';
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  props: [
    'configuration',
    'locale',
    'language',
    'configurationJson',
    'jsonApi',
    'imagePath'
  ],
  components: {
    Loader
  },
  data: () => ({
    loading: true,
    errored: false,
    data: []
  }),
  computed: {
    isButtonClicked: function () {
      return this.submit;
    },
    ...mapGetters({
      getLoadStatus: 'getLoadingStatus',
      getErrorStatus: 'getLoadingError',
      getConfigurations: 'getConfigurationData',
      getParams: 'getParams',
      getParamsUrl: 'getParamsUrl',
      getDataType: 'getConfigurationType',
      getMinistries: 'getConfigurationMinistries'
    })
  },
  methods: {
    getCurrentLocale() {
      return this.$store.getters.getLocale;
    },
    convertLanuage(lang) {
      lang = lang.toLowerCase();
      if (lang.includes('_')) {
        lang = lang.replace(/_/g, '-');

      }

      return lang;
    }
  },
  mounted() {
    this.$nextTick(() => {

      this.loading = this.getLoadStatus;
      this.errored = this.getErrorStatus;


    });
  },
  beforeMount() {
    this.$store.dispatch('setLoadingStatus');
    this.$store.dispatch('setConfigViewStatus', this.configuration);
    this.$store.dispatch('setLocale', this.locale);
    this.$store.dispatch('setLanguage', this.convertLanuage(this.language));
    this.$translate.setLang(this.getCurrentLocale());
    this.$store.dispatch('setApiUrl', this.jsonApi);
    this.$store.dispatch('setImagePath', this.imagePath);
    if (Object.keys(this.configurationJson).length > 0) {
      this.$store.dispatch('setConfigurationData', JSON.parse(this.configurationJson))
        .then(() => {
          this.$store.dispatch('setParams')
            .then(() => {
              // If query contains multiple record caseNumbers
              if (this.getParams.type === 'records' && (this.getParams.recordId && this.getParams.recordId.length > 0)) {
                this.$store.dispatch('queryRecordsById', {
                  url: this.jsonApi,
                  params: this.getParams
                });
              } else {
                const url = Object.entries(this.getParams).map(([key, val]) => `${key}=${val}`).join('&');
                if (this.getMinistries.length > 0 && this.getDataType !== '') {
                  this.$store.dispatch('setParamsUrl', url).then(response => {
                    this.$store.dispatch('setJsonData', {
                      url: this.jsonApi,
                      container: this.getDataType,
                      params: this.getParamsUrl
                    });
                  }, (error) => {
                    console.log('something went wrong while creating params url:', error);
                  });
                } else {
                  if (this.configuration === true) {
                    this.$store.dispatch('setLoadingState', false);
                  }
                }
              }
            }, (error) => {
              console.log('something went wrong while creating params list:', error);
            });
        }, (error) => {
          console.log('error while getting configuration data:', error);
        });
    }
  },
};
</script>
<style lang="scss">


  .v-application--wrap {
    min-height: auto;
    background: white;
  }

  .case__details .more__info {
    margin-bottom: 0px;
  }

  .case__details .accordion__content {
    padding-bottom: 20px;
  }

  .feed-item-container {
    margin-bottom: 2em;
  }

  .feed-item a {
    border-bottom: 1px solid transparent !important;
    color: initial !important;
  }

  .feed-item a:hover {
    border-bottom: 1px solid !important;
    color: #365abd !important
  }

  .feed-item a.attachment {
    color: #000099 !important;
    cursor: pointer;
    text-decoration: none;
    border-bottom: 1px solid #000099 !important;
  }

  .feed-item a span.glyphicon-file {
    top: 3px;
  }

  .feed-item a.attachment:hover {
    color: #365abd !important;
    cursor: pointer;
    text-decoration: none;
    border-bottom: 1px solid #365abd !important;
  }

  .feed-item h6 {
    margin-top: 0;
    margin-bottom: 0.2857rem;
    font-style: normal;
    font-size: 1rem;
  }

  .feed-item .feed-item-aria-label {
    display: none;
  }

  .feed-item .meta p span:nth-of-type(n + 2):not(.meta p span span):not(.meta p .keywords):before {
    content: ", ";
  }

  .feed-item .meta p .keywords span:nth-of-type(n + 2):before {
    content: " ";
  }

.portlet-vahva {
  .loader__container img {
    display: block;
    margin: 0 auto;
  }

  .more__info button {
    padding: 0px;
  }

  #collapsible-0 {
    .feed-item-aria-label {
      display: none;
    }
    p {
      margin-bottom: 0px;
    }
  }
}



  .loader__container {
    width: 50px;
    margin: 20px auto;
  }

  .v-application--wrap {
  }

  .container {
    width: 100%;
    padding: 0;
  }

  .row {
    margin-left: -12px !important;
    margin-right: -12px !important;
  }

  input[type="text"],
  input[type="password"],
  input[type="datetime"],
  input[type="datetime-local"],
  input[type="date"],
  input[type="month"],
  input[type="time"],
  input[type="week"],
  input[type="number"],
  input[type="email"],
  input[type="url"],
  input[type="search"],
  input[type="tel"],
  input[type="color"],
  .uneditable-input {
    border: 0;
    box-shadow: none;
    height: inherit;
  }

  textarea {
    border: 0;
    box-shadow: none;
  }

  input[disabled],
  select[disabled],
  textarea[disabled],
  input[readonly],
  select[readonly],
  textarea[readonly] {
    background-color: transparent;
  }

  .btn-link {
    padding: 0;
    margin-bottom: 0;
  }

  .accordion__content {
    margin-bottom: 20px;
  }

  .documents-heading {
    border-bottom: 1px solid #999;
    color: #222;
    font-size: 1.25em;
    font-weight: 600;
    line-height: 1em;
    margin-bottom: 0.5em;
    padding-bottom: 0.25em;
    padding-left: 0px;
  }


.input-select-wrapper select {
    line-height: 1.5;
  }

  nav[role="navigation"] li + li {
    margin-left: 10px;
  }

  .theme--light.v-application {
    background: transparent;
  }

  .dialog-iframe-popup .theme--light.v-application {
    background: white;
  }

</style>

