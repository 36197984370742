<template>
  <div v-if="showConfig === 'true'">
    <ConfigView />
  </div>
  <div v-else>
    <Portlet />
  </div>

</template>

<script>
import Portlet from '../components/Vahva';
import ConfigView from '@/views/admin/Configuration';
import {mapGetters} from 'vuex';

export default {
  data: () => ({
    showConfig: false,
    async: false
  }),
  computed: {
    ...mapGetters({
      showConfigView: 'getConfigurationViewStatus'
    }),

  },
  mounted() {
    this.showConfig = this.showConfigView;

  },
  components: {
    Portlet,
    ConfigView
  },
  methods: {}
};
</script>
