<template>
  <div>
    <div class="loader__container" role="alertdialog" aria-busy="true" aria-live="assertive">
      <img :src="getImagePath+'/loader'+this.fileType" aria-hidden="true" />
      <span class="sr-only" v-translate>Sisältöä ladataan sivulle...</span>
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';

export default {
  data: () => ({}),
  computed: {
    ...mapGetters({
      getPortetUrl: 'getApiUrl',
      getImagePath: 'getImagePath'
    }),
    fileType: function () {
      const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
      if (isIE11) {
        return '.gif';
      } else {
        return '.svg';
      }
    }
  },
  methods: {}
};
</script>
