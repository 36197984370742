<template>
  <div>
    <v-row>
      <v-col cols="12">
        <label class="font-weight-bold subtitle-1">
          <span v-translate>Tyyppi</span>
        </label>
        <p v-translate>
          Kun valitset yhden asian, siihen liittyvät tiedostot avautuvat suoraan listana asian otsikon alle.
          Jos asioita on useita, saat tiedostot näkyviin asian otsaketta klikkaamalla.
          Kun valitset asiakirjan, saat näkyviin asiakirjalla olevat tiedostot rajaustesi perusteella.
        </p>
        <v-radio-group v-model="materialType" :mandatory="false">
          <v-radio
            id="showBySubject"
            name="materialType"
            :label="t('Asia')"
            v-bind:value="'cases'"
            @change="setType('cases')"
            :checked="this.materialType === 'cases'"
            :aria-checked="this.materialType === 'cases'"
          ></v-radio>
          <v-radio
            id="showByDocuments"
            name="materialType"
            :label="t('Asiakirja')"
            v-bind:value="'records'"
            @change="setType('records')"
            :checked="this.materialType !== 'cases'"
            :aria-checked="this.materialType !== 'cases'"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        {{ searchedMinistry }}
        <label class="font-weight-bold subtitle-1" for="field_selectionRange" v-translate>Laajuus</label>
        <p id="hint_selectionRange" v-translate>Valitse ministeriö(t)</p>
        <v-combobox
          id="field_selectionRange"
          aria-describedby="hint_selectionRange"
          v-model="selectionRange"
          :items="ministriesList"
          item-text="title"
          item-value="abbr"
          outlined
          multiple
          chips
          deletable-chips
          clearable
          v-on:change="setMinistries"
          :menu-props="{'maxHeight': 800, 'maxWidth': 400}"
        >
          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip
              v-bind="attrs"
              :input-value="selected"
              color="primary"
              close
              @click="select"
              @click:close="removeMinistry(item)"
            >
              <strong>{{ item.abbr }}</strong>
            </v-chip>
          </template>
        </v-combobox>
        <button id="closeMinistrySelectionButton"
          type="button"
          class="btn btn-primary btn-default float-right">
          <span class="lfr-btn-label">Sulje ministeriövalinta</span>
        </button>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <label class="font-weight-bold subtitle-1" v-translate>
          Laatimisaika
        </label>
        <p id="hint_filterRules" v-translate>
          Valitse kalenterinäkymästä alkamis- ja päättymisajankohta.
          Mikäli et aseta ajankohtia, näytetään kaikki hakutulokset.
        </p>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-menu
          v-model="filterRangeStartPicker"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :value="computedDateFormattedMomentjs(filterRangeStartDate)"
              :label="t('Alkaen')"
              prepend-icon="event"
              readonly
              outline
              v-on="on"
              clearable
              @click:clear="clearStartDate"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="filterRangeStartDate"
            @input="filterRangeStartPicker = false"
            :locale="languageCode"
            @change="setCreationDateFrom(filterRangeStartDate)"
            first-day-of-week="1"
            :max="maxStartDate"
            no-title
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-menu
          v-model="filterRangeEndPicker"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
          @blur="setCreationDateTo"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              :value="computedDateFormattedMomentjs(filterRangeEndDate)"
              :label="t('Päättyen')"
              prepend-icon="event"
              readonly
              outline
              clearable
              @click:clear="clearEndDate"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="filterRangeEndDate"
            @input="filterRangeEndPicker = false"
            :locale="languageCode"
            @change="setCreationDateTo(filterRangeEndDate)"
            :max="maxEndDate"
            :min="minEndDate"
            no-title
            first-day-of-week="1"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <label class="font-weight-bold subtitle-1" v-translate>
          Aineistojen suodatus
        </label>
        <v-radio-group v-model="configurationListingAsMode" :mandatory="false">
          <v-radio
            id="listAutomatic"
            name="listingType"
            :label="t('Automaattisesti. Hakusanan avulla voidaan valita haettavat') + ' ' + getTypeText"
            value="automatic"
            :checked="isAutomaticMode"
            :aria-checked="isAutomaticMode"
          ></v-radio>
          <v-radio
            id="listManual"
            name="listingType"
            :label="t('Manuaalisesti. Jos tekemäsi haku täyttää ehdot heti, voit tallentaa haun ilman valintoja. Muutoin listauksesta voidaan valita näytettävät') + ' ' + getTypeText"
            value="manually"
            :checked="!isAutomaticMode"
            :aria-checked="!isAutomaticMode"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="searchCols">
        <label class="font-weight-bold subtitle-1" for="keyword_search">
          {{ getSearchLabel }}
        </label>
        <p v-if="isAutomaticMode && this.type === 'cases'" id="hint_filterRules2" v-translate>
          Suodata listasta asia(t) hakusanalla. Haku kohdistuu asian otsikkoon ei numeroon.
          Kun haluat tehdä uuden haun, klikkaa ruksia hakukentän lopusta, niin aiempi haku nollautuu.
        </p>
        <p v-if="isAutomaticMode && this.type !== 'cases'" id="hint_filterRules2" v-translate>
          Suodata listasta asiakirja(t) hakusanalla. Haku kohdistuu asiakirjan otsikkoon ei numeroon.
          Kun haluat tehdä uuden haun, klikkaa ruksia hakukentän lopusta, niin aiempi haku nollautuu.
        </p>
        <p v-if="!isAutomaticMode && this.type === 'cases'" id="hint_filterRules2" v-translate>
          Suodata listasta asia(t) hakusanalla. Haku kohdistuu asian otsikkoon ja numeroon.
          Kun haluat tehdä uuden haun, klikkaa ruksia hakukentän lopusta, niin aiempi haku nollautuu.
        </p>
        <p v-if="!isAutomaticMode && this.type !== 'cases'" id="hint_filterRules2" v-translate>
          Suodata listasta asiakirja(t) hakusanalla. Haku kohdistuu asiakirjan otsikkoon ja numeroon.
          Kun haluat tehdä uuden haun, klikkaa ruksia hakukentän lopusta, niin aiempi haku nollautuu.
        </p>
        <p>
          Haku tehdään automaattisesti kun hakuteksti muuttuu, älä siis paina Enteriä koska se suorittaa tallennuksen.
        </p>
        <v-text-field
          v-model="search"
          single-line
          hide-details
          class="mb-10"
          clearable
          id="keyword_search"
        ></v-text-field>
      </v-col>
      <v-col cols="6" v-show="isAutomaticMode &&  search !== '' && search !== null">
        <label class="font-weight-bold subtitle-1" for="searchMethodFree" v-translate>
          Valitse kuinka haku kohdistuu otsikossa
        </label>
        <v-radio-group v-model="searchMethod" :mandatory="false">
          <v-radio
            id="searchMethodFree"
            name="searchMethod"
            :label="t('Hakusana voi olla missä tahansa kohtaa otsikkoa')"
            value="free"
            :checked="this.searchMethod === 'free'"
            :aria-checked="this.searchMethod === 'free'"
          ></v-radio>
          <v-radio
            id="searchMethodFromStart"
            name="searchMethod"
            :label="t('Alkaa hakusanalla')"
            value="fromStart"
            :checked="this.searchMethod === 'fromStart'"
            :aria-checked="this.searchMethod === 'fromStart'"
          />
          <v-radio
            id="searchMethodExcatly"
            name="searchMethod"
            :label="t('Otsikko koostuu kokonaan hakusanasta')"
            value="exactly"
            :checked="this.searchMethod === 'exactly'"
            :aria-checked="this.searchMethod === 'exactly'"
          />
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="getFilteredItems"
          :search="search"
          item-key="title"
          class="elevation-1"
          id="manuallySelectedDocuments"
          :value="selected"
          :show-select="showSelected"
          loading="true"
          @click:row="changeSelected()"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import moment from 'moment';

export default {
  data: () => ({
    timeOut: null,
    materialType: 'cases',
    selectionRange: [],
    selectedMinistry: [],
    searchedMinistry: '',
    filterRangeStartDate: '',
    filterRangeEndDate: '',
    filterRangeStartPicker: false,
    filterRangeEndPicker: false,
    filterByStatus: 'Käsittelyssä',
    selectable: false,
    search: '',
    searchMethod: '',
    numOfHits: null,
    selected: [],
    chips: [],
    headers: [
      {
        text: 'Numero',
        sortable: false,
        align: 'left',
        value: 'caseNumber',
        filterable: false
      },
      {
        text: 'Otsikko',
        value: 'title'

      }, {
        text: 'Määrä',
        value: 'caseFileRelation',
        filterable: false
      }
    ],
    items: []
  }),
  computed: {
    isAutomaticMode() {
      return this.getConfigurationListingAs === 'automatic';
    },
    configurationListingAsMode: {
      get() {
        return this.getConfigurationListingAs;
      },
      set(val) {
        this.setListingAs(val);
      }
    },
    ...mapGetters({
      locale: 'getLocale',
      languageCode: 'getLanguage',
      type: 'getConfigurationType',
      ministriesList: 'getStaticMinistries',
      selectedMinistries: 'getConfigurationMinistry',
      getKeywords: 'getConfigurationKeywords',
      caseNumbers: 'getConfigurationCaseNumbers',
      status: 'getConfigurationCaseStatus',
      apiUrl: 'getApiUrl',
      paramsUrl: 'getParamsUrl',
      manuallySelected: 'getConfigurationManuallySelected',
      getConfigurationListingAs: 'getConfigurationListingAs',
      getItems: 'getFilteredItems',
      getCaseNumbers: 'getConfigurationCaseNumbers',
      getConfigurationSearchMethod: 'getConfigurationSearchMethod',
      getLoadingStatus: 'getLoadingStatus'
    }),
    ...mapState({
      configuration: 'configuration',
      stateCases: 'cases',
      stateRecords: 'records',
      stateFiltered: 'filteredList',
      stateSelected: 'selectedList'
    }),
    numberOfHits() {
      return this.items.length;
    },
    showSelected() {
      this.selectable = !this.isAutomaticMode;
      return this.selectable;
    },
    getSelectedItems() { // not called?
      return this.stateSelected;
    },
    getFilteredItems() {
      
      // Is this needed here? Causes side effects, maybe should be moved to selection related code.
      if (this.getCaseNumbers !== undefined && this.getCaseNumbers.length > 0) {
        this.selected = this.getItems.filter(item => this.getCaseNumbers.includes(item.caseNumber));
      } else if (this.stateSelected !== undefined && this.stateSelected.length > 0) {
        this.selected = this.stateSelected;
      }
      
      let filteredItems;
      if (this.isAutomaticMode && this.search) {
        // Separated just to make this more clear
        if (this.searchMethod === 'free') {
          filteredItems = this.getItems.filter((item)=>{
            const stringIndex = item.title.toLowerCase().indexOf(this.search.toLowerCase(),0);
            return (stringIndex !== -1);
          });
        } else if (this.searchMethod === 'fromStart') {
          filteredItems = this.getItems.filter((item)=>{
            const stringIndex = item.title.toLowerCase().indexOf(this.search.toLowerCase(),0);
            return (stringIndex !== -1 && stringIndex === 0);
          });
        } else {
          filteredItems = this.getItems.filter((item)=>{
            return (item.title.toLowerCase() === this.search.toLowerCase());
          });
        }

        this.setFilteredList(filteredItems);
        return filteredItems;
      }

      return this.getItems;
      
    },
    getSelected() { // Not called anywhere?
      if (this.getCaseNumbers !== undefined && this.getCaseNumbers.length > 0) {
        this.selected = this.getItems.filter(item => this.getCaseNumbers.includes(item.caseNumber));
        return this.selected;
      }
      return [];
    },
    searchCols() {
      if (this.isAutomaticMode && this.search !== '' && this.search !== null) {
        return '6';
      } else {
        return '12';
      }
    },
    getTypeText() {
      if (this.type === 'cases') {
        return 'asiat';
      } else {
        return 'asiakirjat';
      }
    },
    getSearchLabel() {
      if (this.isAutomaticMode) {
        return 'Hae ainestoa';
      } else {
        return 'Suodata listauksesta';
      }
    },
    maxEndDate() {
      return moment().format('YYYY-MM-DD');
    },
    // FIXME: what is default value if start date is not defined?
    minEndDate() {
      if (this.filterRangeStartDate !== undefined && this.filterRangeStartDate !== '') {
        return moment(this.filterRangeStartDate).format('YYYY-MM-DD');
      }
    },
    maxStartDate() {
      if (this.filterRangeEndDate !== undefined && this.filterRangeEndDate !== '') {
        return moment(this.filterRangeEndDate).format('YYYY-MM-DD');
      } else {
        return moment().format('YYYY-MM-DD');
      }

    }

  },
  methods: {
    ...mapActions({
      resetFilters: 'resetFilters',
      setFilteredList: 'setFilteredList',
      setSelected: 'setSelected',
      setConfigCaseStatus: 'setConfigCaseStatus',
      setConfigType: 'setConfigType',
      setConfigKeywords: 'setConfigKeywords',
      setConfigSearchMethod: 'setConfigSearchMethod'
    }),
    setFilters() {
      // temporay disable debounce as search results where not in sync.
      // this.debounce(this.doResetFilters, 500);
      this.resetFilters();

    },
    debounce(callback, wait) {
      if (this.timeOut) {
        clearTimeout(this.timeOut);
        this.timeOut=null;
      }

      this.timeOut = setTimeout(()=>{
        callback();
      },wait);

    },
    changeSelected() {
      console.log('do something');
    },
    setListingAs(mode) {
      if (mode === 'automatic') {
        this.headers[0].filterable = false;
      } else {
        this.headers[0].filterable = true;
        if (this.search !== '') {
          this.setConfigKeywords('');
          this.resetFilters();
        }
      }

      this.$store.dispatch('setConfigurationListingAs', mode);

    },
    removeMinistry(item) {
      this.selectionRange.splice(this.selectionRange.indexOf(item), 1);
      this.selectionRange = [...this.selectionRange];
      this.$store.dispatch('setConfigurationDataset', { key: 'selectedMinistries', value: this.selectionRange });
      this.setMinistry(this.selectionRange);
      this.resetFilters();
    },
    // FIXME: Same store function used for two different things
    removeChip(index) {
      if (this.configuration.type === 'records') {
        this.$store.dispatch('removeByIndex', { key: 'recordId', value: index });
      } else {
        this.$store.dispatch('removeByIndex', { key: 'configuration["caseNumber"]', value: index });
      }

    },
    computedDateFormattedMomentjs(value) {
      value = value.replace(/\./g,':');
      return value ? moment(value).format('D.M.YYYY') : '';
    },
    setType(type) {
      this.setConfigType(type);
      this.resetFilters();
    },
    setMinistries() {
      for (const [i, value] of this.selectionRange.entries()) {
        if (typeof value === 'string') {
          this.selectionRange.splice(i, 1);
        }
      }

      this.$store.dispatch('setConfigurationDataset', { key: 'selectedMinistries', value: this.selectionRange });
      this.setMinistry(this.selectionRange);

      this.resetFilters();
    },
    setCreationDateFrom(val) {
      const date = moment(val).format('YYYY-MM-DDT00:00:00-00:00');
      this.$store.dispatch('setConfigurationDataset', { key: 'creationDateFrom', value: date });
      this.resetFilters();

    },
    setCreationDateTo(val) {
      const date = moment(val).format('YYYY-MM-DDT00:00:00-00:00');
      this.$store.dispatch('setConfigurationDataset', { key: 'creationDateTo', value: date });
      this.resetFilters();
    },
    clearStartDate() {
      this.filterRangeStartDate = '';
      this.$store.dispatch('setConfigurationDataset', { key: 'creationDateFrom', value: '' });
      this.resetFilters();
    },
    clearEndDate() {
      this.filterRangeEndDate = '';
      this.$store.dispatch('setConfigurationDataset', { key: 'creationDateTo', value: '' });
      this.resetFilters();
    },
    setMinistry(items) {
      const data = [];
      items.filter((item) => {
        return Object.entries(item).forEach(([key, value]) => {
          if (key === 'abbr') {
            data.push(value);
          }
        });
      });
      this.$store.dispatch('setConfigurationDataset', { key: 'ministries', value: data });
    },
    getItemsValues() {
      return Object.values(this.getItems);
    },
    setSelectedItems() {  // this functions probably not needed as not called at all
      if (this.configuration.type === 'records') {
        if (this.configuration.recordId && this.configuration.recordId.length > 0) {
          const array = this.configuration.recordId;
          this.selected = this.getItems.filter(item => {
            return array.includes(item.nativeId);
          });
        }
      } else {
        if (this.configuration.caseNumber && this.configuration.caseNumber.length > 0) {

          this.selected = this.getItems.filter(item => this.configuration.caseNumber.includes(item.caseNumber));
        }
      }
    }
  },
  watch: {
    selected(newVal, oldVal) {
      const ids = [];
      if (newVal.length !== oldVal.length) {
        if (this.configuration.type === 'records') {
          Object.keys(this.selected).forEach(item => {
            ids.push(this.selected[item].nativeId);
          });
          this.$store.dispatch('setConfigurationDataset', { key: 'recordId', value: ids });
          this.$store.dispatch('setSelected', newVal);
        } else {
          Object.keys(this.selected).forEach(item => ids.push(this.selected[item].caseNumber));
          this.$store.dispatch('setConfigurationDataset', { key: 'caseNumber', value: ids });
          this.$store.dispatch('setSelected', newVal);
        }

        if (oldVal.length > 0 && newVal.length === 0) {
          this.$store.dispatch('setConfigurationDataset', { key: 'recordId', value: [] });
          this.$store.dispatch('setConfigurationDataset', { key: 'caseNumber', value: [] });
          this.$store.dispatch('setFilteredList', []);
          this.$store.dispatch('setSelected', []);
          this.resetFilters();
        }
      }

    },
    search(newVal, oldVal) {
      if (this.isAutomaticMode) {

        // Temporarily disabled as search results are not in sync

        /* 
         if (newVal !== null && newVal.length < 3) {
          return;
        }
        */
        if (newVal && newVal !== '') {
          if (this.searchMethod === 'free') {
            this.setConfigKeywords(`%${this.search}%`);
          } else if (this.searchMethod === 'fromStart') {
            this.setConfigKeywords(`${this.search}%`);
          } else {
            this.setConfigKeywords(this.search);
          }

          this.resetFilters();
        } else {
          this.setConfigKeywords('');
          this.resetFilters();
        }
      } else {
        if (this.getKeywords !== '') {
          this.setConfigKeywords('');
          this.resetFilters();
        }
      }
    },
    searchMethod(newVal) {
      if (this.isAutomaticMode) {
        if (newVal !== null && newVal !== '') {
          if (this.searchMethod === 'free') {
            this.setConfigKeywords(`%${this.search}%`);
          } else if (this.searchMethod === 'fromStart') {
            this.setConfigKeywords(`${this.search}%`);
          } else {
            this.setConfigKeywords(this.search);
          }

          this.resetFilters();
        } else {
          this.setConfigKeywords('');
          this.resetFilters();
        }
      } else {
        if (this.getKeywords !== '') {
          this.setConfigKeywords('');
          this.resetFilters();
        }
      }

      this.setConfigSearchMethod(this.searchMethod);
    },
  },
  mounted() {
    this.$nextTick(() => {
      // The whole view is rendered, so we can safely access or query

      // Ensure once more we have all cases in the search
      this.setConfigCaseStatus('Kaikki');
      this.resetFilters();
      this.materialType = this.type;
      this.selectionRange = this.selectedMinistries;

      if (this.status) {
        this.filterByStatus = this.status;
      }

      if (this.getKeywords && this.getKeywords.length) {
        this.search = this.getKeywords.replace(/%/g, '');
      }

      const formattedEndDate = moment(new Date(this.configuration.creationDateTo)).format('YYYY-MM-DD');
      this.filterRangeStartDate = this.configuration.creationDateFrom;

      // if there is no end date set we will use today
      this.filterRangeEndDate = !this.configuration.creationDateTo ? moment().format('YYYY-MM-DD') : formattedEndDate;

      this.searchMethod=this.getConfigurationSearchMethod;
      if (!this.getConfigurationSearchMethod) {
        this.searchMethod = 'free';
      }

    });
  },
  unmounted () {
    clearTimeout(this.timeOut);
    this.timeOut=null;
  },
};
</script>
<style>
label {
  margin-bottom: 0 !important;
}
</style>
